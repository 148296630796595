import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { createDynamicURL } from '../../utils/dynamicParams';
// ----------------------------------------------------------------------
const initialState = {
    status: 'idle',
    configs: [],
    configsByCategory: [],
    config: null,
    meta: { currentPage: 0, nextPage: false, prevPage: false, totalPages: 0, totalRecords: 0 },
};
// ----------------------------------------------------------------------
export const getConfig = createAsyncThunk('config/getConfig', async ({ isPaging = false, id }) => {
    let data;
    const queryParams = {
        isPaging,
    };
    const url = createDynamicURL(`/configs/${id}`, queryParams);
    try {
        const response = await axios.get(url);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getConfigs = createAsyncThunk('config/getConfigs', async ({ isPaging = false, code = '', scopeBranch = '', scopeAgency = '', scopeClass = '', }) => {
    let data;
    const queryParams = {
        search: {
            code,
            scopeBranch,
            scopeAgency,
            scopeClass,
        },
        isPaging,
    };
    const url = createDynamicURL(`/configs`, queryParams);
    try {
        const response = await axios.get(url);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getConfigsByCategory = createAsyncThunk('config/getConfigsByCategory', async ({ scope = '', }) => {
    let data;
    try {
        const response = await axios.get(`/configs/byCategories?scope=${scope}`);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
// ----------------------------------------------------------------------
const slice = createSlice({
    name: 'config',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getConfig.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getConfig.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.config = action.payload.data;
        })
            .addCase(getConfig.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getConfigs.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getConfigs.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.configs = action.payload.data;
        })
            .addCase(getConfigs.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getConfigsByCategory.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getConfigsByCategory.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.configsByCategory = action.payload.data;
        })
            .addCase(getConfigsByCategory.rejected, (state, action) => {
            state.status = 'failed';
        });
    },
});
// Reducer
export const reducer = slice.reducer;
export default slice.reducer;
